import { Dialog } from "@mui/material";
import { Content } from "./styled";
// import {
//   Card,
//   CardContainer,
//   CardHeader,
//   CardItem,
//   ModalContainer,
//   Title,
// } from "./styled";

const Popup = ({ isOpen, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        {/* <ModalContainer> */}
        {/* <DialogTitle>
          <Title>¡INFORMACIÓN IMPORTANTE!</Title>
        </DialogTitle>
        <DialogContent>
          <CardContainer>
            <Card>
              <CardHeader>
                <p style={{ fontWeight: "bold" }}>LUNES</p>
                <p>26 DE AGOSTO</p>
              </CardHeader>
              <CardItem>
                <p style={{ color: "#285a9b", fontWeight: "bold" }}>8:00</p>
                <p>Acreditaciones</p>
              </CardItem>
              <CardItem style={{ borderTop: "1px solid #7edbaa" }}>
                <p style={{ color: "#285a9b", fontWeight: "bold" }}>
                  9:00 - 18:30
                </p>
                <p>Agape de bienvenida al cierre del día</p>
              </CardItem>
            </Card>
            <Card>
              <CardHeader>
                <p style={{ fontWeight: "bold" }}>MARTES</p>
                <p>27 DE AGOSTO</p>
              </CardHeader>
              <CardItem>
                <p style={{ color: "#285a9b", fontWeight: "bold" }}>
                  9:00 - 18:30
                </p>
                <p>Almuerzo de Networking.</p>
                <p>Entradas a la venta</p>
              </CardItem>
            </Card>
          </CardContainer>
        </DialogContent> */}
        {/* </ModalContainer> */}
        <Content>
          <img
            src="/assets/Popup/popup.webp"
            alt="Información importante"
            height={750}
            width={600}
          />
        </Content>
      </Dialog>
    </>
  );
};

export default Popup;
