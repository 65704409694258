import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

export const CardContainer = styled.div`
  position: relative;
  outline: 1px solid #285a9b;
  margin-bottom: 48px;
  z-index: 1;
  margin-left: ${(p) => p.spacing}px;
  width: calc(100% - 16px);

  @media (max-width: 600px) {
    margin-bottom: 32px;
    width: 100%;
    margin-left: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.direction === "reverse" ? "row-reverse" : "row")};
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 100%;
  background-color: white;
  z-index: 1;
`;

export const Title = styled(Typography)`
  color: white;
  flex: 1;
  text-align: left;
`;

export const ImageContainer = styled.div`
  width: 240px;
  min-width: 240px;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
    height: 180px;
    border-top: 1px solid #285a9b;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CardHeader = styled.div`
  padding: 8px 16px;
  background-color: #285a9b;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  & svg path {
    fill: white;
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;
`;

export const CardContent = styled.div`
  padding: 16px;
  z-index: 1;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;

  & svg path,
  & svg circle {
    fill: #285a9b;
  }
`;

export const Link = styled.a`
  color: #63b66e;
  font-weight: 700;
  margin-left: 4px;
`;

export const TrapezoidBackground = styled.div`
  position: absolute;
  left: ${(props) => -1 * (props.spacing - 1)}px;
  top: ${(props) => props.spacing + 1}px;
  border-bottom: ${(props) => props.height - 2}px solid #fff;
  border-right: ${(props) => props.height - 2}px solid transparent;
  width: calc(100% - ${(props) => props.height - 2}px);
  z-index: -1;
`;

export const TrapezoidBorder = styled.div`
  position: absolute;
  left: ${(props) => -1 * props.spacing}px;
  top: ${(props) => props.spacing}px;
  border-bottom: ${(props) => props.height}px solid #285a9b;
  border-right: ${(props) => props.height}px solid transparent;
  width: calc(100% - ${(props) => props.height - 3}px);
  z-index: -1;
`;

export const TrapezoidBackgroundReverse = styled.div`
  position: absolute;
  right: ${(props) => -1 * (props.spacing - 1)}px;
  top: ${(props) => props.spacing + 1}px;
  border-bottom: ${(props) => props.height - 2}px solid #fff;
  border-left: ${(props) => props.height - 2}px solid transparent;
  width: calc(100% - ${(props) => props.height - 2}px);
  z-index: -1;
`;

export const TrapezoidBorderReverse = styled.div`
  position: absolute;
  right: ${(props) => -1 * props.spacing}px;
  top: ${(props) => props.spacing}px;
  border-bottom: ${(props) => props.height}px solid #285a9b;
  border-left: ${(props) => props.height}px solid transparent;
  width: calc(100% - ${(props) => props.height - 3}px);
  z-index: -1;
`;

export const BookButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%) skew(-30deg);
  display: inline-block;
  color: white;
  font-weight: 800;
  border-radius: 0px;
  width: 140px;
  background-color: #63b66e;
  font-size: 12px;
  padding: 4px 24px;

  & > span {
    display: inline-block;
    transform: skew(30deg);
  }

  &:hover {
    background-color: rgba(100, 183, 111, 0.9) !important;
  }
`;
