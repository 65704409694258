import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { Title, ContentContainer, CardHeader, ContentItem } from "./styles";
import { Grid, Typography } from "@mui/material";

const PressCard = ({ title, items }) => {
  return (
    <>
      <CardHeader>
        <Grid display="flex" direction="row">
          <SendIcon sx={{ transform: "rotate(-35deg)", marginRight: "16px" }} />
          <Title variant="h5">{title}</Title>
        </Grid>
      </CardHeader>
      <ContentContainer>
        {items.map(({ name, url }) => (
          <ContentItem href={url} target="_blank" rel="noreferrer">
            <Typography
              variant="p"
              textAlign="left"
              fontWeight={600}
              lineHeight={1.2}
            >
              {name}
            </Typography>
          </ContentItem>
        ))}
      </ContentContainer>
    </>
  );
};

export default PressCard;
