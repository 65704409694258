import styled from "@emotion/styled";

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  border: 1px solid #285a9b;
`;

export const ExpandenContent = styled.div`
  visibility: ${(p) => (p.visible ? "visble" : "hidden")};
  opacity: ${(p) => (p.visible ? 1 : 0)};
  height: ${(p) => (p.visible ? "fit-content" : 0)};
  transition: opacity 0.3s;
`;

export const CardContent = styled.div`
  background-color: white;
  overflow: hidden;

  height: ${(props) => (props.isOpen ? "780px" : "100px")};
  transition: height 0.3s ease-in-out;

  @media (max-width: 990px) {
    height: ${(props) => (props.isOpen ? "800px" : "100px")};
  }

  @media (max-width: 560px) {
    height: ${(props) => (props.isOpen ? "700px" : "100px")};
  }
`;

export const Separator = styled.div`
  overflow: hidden;
  position: relative;
  height: 300px;

  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 280px;
    width: 100%;
  }

  & > div:nth-child(2) {
    position: absolute;
    background-color: #fff;
    height: 180px;
    width: 150%;
    top: 220px;
    left: -25%;
    transform: rotate(16deg);
  }
`;

export const ImageContainer = styled.div``;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ArrowContainer = styled.div`
  position: absolute;
  z-index: 4;
  padding: 24px 6px;
  background-color: #64b76f;
  left: 50%;
  top: 170px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    fill: white;
    transform: ${(props) => (props.isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
    transition: transform 0.3s ease-in-out;
  }
`;

export const CardContentHeader = styled.div`
  padding: 0px 24px 32px;

  @media (max-width: 690px) {
    padding: 0px 16px 24px;
  }
`;

export const ListItem = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid #285a9b;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 690px) {
    padding: 12px;
  }
`;
