import styled from "@emotion/styled";
import { Box, Button, Link } from "@mui/material";

export const NavbarButton = styled(Button)`
  width: 180px;
  height: 36px;
  font-weight: 700;
  transform: skew(-30deg);
  color: white;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 8px 24px;
  /* visibility: hidden; */
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  @media (max-width: 890px) {
    width: 180px;
    height: 36px;
    padding: 8px 24px;
  }
  @media (max-width: 500px) {
    width: 120px;
    height: 36px;
    padding: 8px 0px;
  }
`;

export const NavbarButtonContent = styled.span`
  transform: skew(30deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  @media (max-width: 500px) {
    justify-content: end;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline solid #000;
  }
`;

export const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
justify-content: center;
  height: 56px;

  @media (max-width: 990px) {
    flex: 1;
  }
`;
