import React, { useCallback, useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import {
  Title,
  CardContent,
  CardHeader,
  CardContainer,
  CardNumber,
  CardNumberContainer,
  CardNumberBackground,
  CardNumberBorder,
  Content,
  TrapezoidBackground,
  TrapezoidBorder,
} from "./styles";
import { Typography } from "@mui/material";

const DiscoverCard = ({ title, body, number }) => {
  const containerRef = useRef();
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 600);
    if (containerRef.current && height !== containerRef.current.clientHeight) {
      setHeight(containerRef.current.clientHeight);
    }
  }, [height]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const size = isMobile ? 110 : height;

  return (
    <CardContainer>
      {!isMobile && (
        <>
          <TrapezoidBorder height={size} spacing={16} />
          <TrapezoidBackground height={size} spacing={16} />
        </>
      )}
      <Content ref={containerRef}>
        <CardHeader triangleWidth={size + 32}>
         {!isMobile &&  <SendIcon sx={{ transform: "rotate(-135deg)" }} />}
          <Title variant="h5">{title}</Title>
        </CardHeader>
        <CardContent>
          <Typography variant="body2" textAlign="left" fontWeight={600} lineHeight={1.2}>
           {body}
          </Typography>
        </CardContent>
      </Content>
      <CardNumberContainer>
        <CardNumber>
          <Typography variant="h6" fontWeight={500}>{`_0${number}`}</Typography>
        </CardNumber>
        <CardNumberBorder height={size} />
        <CardNumberBackground height={size} />
      </CardNumberContainer>
    </CardContainer>
  );
};

export default DiscoverCard;
