import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const Title = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Button2 = styled(Button)`
  width: 180px;
  transform: skew(-30deg);
  color: white;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 8px 16px;
  margin: 4px 0px 32px 12px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
`;
