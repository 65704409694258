import { Box, Grid } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./index.css";
import { Subtitle, Title } from "./styles";

const supporters = [
  { url: "/assets/Institutional/bid invest.png", name: "BID Invest" },
  { url: "/assets/Institutional/ifc.png", name: "IFC" },
  { url: "/assets/Institutional/galicia.png", name: "Galicia" },
  { url: "/assets/Institutional/fundea.png", name: "Fundea" },
  { url: "/assets/Institutional/banco.png", name: "Banco Corrientes" },
  { url: "/assets/Institutional/bice.png", name: "Bice" },
  { url: "/assets/Institutional/bch.png", name: "Banco del Chubut" },
  { url: "/assets/Institutional/s&p.png", name: "S&P Global Ratings" },
  { url: "/assets/Institutional/bbva.png", name: "BBVA" },
  { url: "/assets/Institutional/deloitte.png", name: "Deloitte" },
  {
    url: "/assets/Institutional/caf.png",
    name: "Banco de desarrollo de américa latina y el caribe",
  },
  {
    url: "/assets/Institutional/cfi.png",
    name: "Consejo Federal de Inversiones",
  },
  {
    url: "/assets/Institutional/banco tdf.png",
    name: "Banco tierra del fuego",
  },
  { url: "/assets/Institutional/bica.png", name: "Banco BICA" },
  { url: "/assets/Institutional/municipal.png", name: "Banco Municipal" },
  { url: "/assets/Institutional/coinag.png", name: "Banco Coinag" },
  { url: "/assets/Institutional/formosa.png", name: "Banco Formosa" },
  { url: "/assets/Institutional/neuquen.png", name: "Banco de Neuquén" },
  { url: "/assets/Institutional/bancor.png", name: "BANCOR" },
  { url: "/assets/Institutional/chaco.png", name: "Banco de Chaco" },
  { url: "/assets/Institutional/media partner.png", name: "Media Partner" },
  { url: "/assets/Institutional/ga.png", name: "Gerencia Ambiental" },
  {
    url: "/assets/Institutional/el congreso apoya.png",
    name: "El congreso apoya",
  },
  { url: "/assets/Institutional/cascos verdes.png", name: "Cascos Verdes" },
  { url: "/assets/Institutional/aves argentinas.png", name: "Aves Argentinas" },
  { url: "/assets/Institutional/tcs.png", name: "The Carbon Sinks" },
  { url: "/assets/Institutional/nosacompan.png", name: "Nos acompañan" },
  { url: "/assets/Institutional/catena.png", name: "D.V. Catena" },
  { url: "/assets/Institutional/felicien.png", name: "Saint Felicien" },
  // { url: "/assets/Institutional/logo_12.png", name: "Banco Nación" },
];

const InstitutionalSection = () => {
  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      className="institucional"
      sx={{
        mb: 4,
        py: 4,
        width: "100%",
        backgroundColor: "rgb(100, 183, 111)",
      }}
    >
      <img
        src={"assets/Logos/congreso-small-white.png"}
        alt="Logo del congreso"
        height="60px"
      />
      <Title>APOYO INSTITUCIONAL</Title>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        paddingTop="16px"
      >
        <img
          width="360px"
          style={{ maxWidth: "100%" }}
          src={"/assets/Institutional/logo_12.png"}
          alt={"Banco Nación"}
        />
        <Subtitle>Main Sponsor</Subtitle>
      </Box>
      <Swiper
        modules={[Pagination, Autoplay]}
        direction="horizontal"
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          567: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        spaceBetween={40}
        pagination={{ clickable: false }}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
        }}
      >
        {supporters.map(({ url, name }) => (
          <SwiperSlide>
            <img src={url} alt={name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );
};

export default InstitutionalSection;
