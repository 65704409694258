import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  background-color: #285a9b;
  padding: 48px 48px 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & > * {
    max-width: 230px;
    margin: 0 auto;
  }
`;

export const Text = styled(Typography)`
  visibility: ${(p) => p.visible ? 'visble': 'hidden'};
  opacity:  ${(p) => p.visible ? 1: 0};
  height:  ${(p) => p.visible ? 'fit-content': 0};
  transition: opacity 0.3s;
`;

export const CardContent = styled.div`
  background-color: white;
  padding: 0px 24px 32px;
  overflow: hidden;

  height: ${(props) => (props.isOpen ? "500px" : "70px")};
  transition: height 0.3s ease-in-out;

  @media (max-width: 690px) {
    padding: 0px 16px 24px;
  }
`;

export const Separator = styled.div`
  overflow: hidden;
  position: relative;
  height: 180px;

  & > div:first-child {
    position: absolute;
    top: -90px;
    left: -25%;
    height: 180px;
    width: 150%;
    background-color: #285a9b;
    transform: rotate(16deg);
  }

  & > div:nth-child(2) {
    position: absolute;
    background-color: #fff;
    height: 180px;
    width: 150%;
    top: 80px;
    left: -25%;
    transform: rotate(16deg);
  }
`;

export const LineDivider = styled.div`
  height: 20px;
  background-color: #64b76f;
  position: absolute;
  top: calc(50% - 20px);
  left: -40px;
  width: calc(100% + 80px);
  z-index: 3;
  transform: rotate(16deg);
`;

export const ArrowContainer = styled.div`
  position: absolute;
  z-index: 4;
  padding: 24px 6px;
  background-color: #64b76f;
  left: 50%;
  top: calc(50% - 8px);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    fill: white;
    transform: ${(props) => (props.isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
    transition: transform 0.3s ease-in-out;
  }
`;
