import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const Container = styled(Grid)`
  width: 100%;
  max-width: 560px;
  flex-direction: column;
  align-content: center;
  @media (max-width: 900px) {
    max-width: 60%;
  }
  @media (max-width: 560px) {
  }
`;
