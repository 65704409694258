import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const GridContainer = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 32px 0;

  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Button2 = styled(Button)`
  width: 180px;
  transform: skew(-30deg);
  color: white;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 8px 16px;
  margin: 4px 0px 32px 12px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
`;

export const SponsorTab = styled.div`
  background-color: #285a9b;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    z-index: 2;
    border-top: 40px solid white;
    border-right: 40px solid transparent;
  }
  &::after {
    position: absolute;
    content: ${(props) => (props.soldOut ? "'SOLD OUT'" : "")};
    background-color: #f00;
    color: #fff;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 40px;
    height: 100%;
    font-size: 10px;
    text-align: center;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    text-orientation: mixed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  cursor: pointer;
  /* &:hover */
`;

export const Modal = styled.div`
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto; /* 10% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-height: 60%;
  overflow: hidden;
  height: 100%;
`;
export const ModalHeader = styled.div`
  display: flex;
  direction: row;
  justify-content: center;
  position: relative;
`;

export const ModalBody = styled.div`
  overflow: auto;
  height: calc(100% - 28px);
  margin: 8px 0;
`;

export const BgImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  @media (max-width: 990px) {
    display: none;
  }
`;
