import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ContainerHeader = styled.div`
  display: flex;
  direction: row;
  background-color: #e5ecf0;
  gap: 60px;
  padding: 24px 120px 48px;
  @media (max-width: 1440px) {
    padding: 24px 56px;
  }
  @media (max-width: 1200px) {
    padding: 24px 24px;
  }
  @media (max-width: 990px) {
    padding: 8px 36px;
    gap: 0px;
    flex-direction: column;
  }
  @media (max-width: 690px) {
    padding: 8px 8px;
    gap: 0px;
  }
`;
export const ImagenHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > img {
    margin-top: 16px;
    width: 480px;
    min-width: 50px;
    height: auto;
  }
  @media (max-width: 990px) {
    justify-content: center;
  }
`;

export const InfoHeader = styled.div`
  max-width: 100%;
`;

export const ContainerLogos = styled.div`
  display: flex;
  direction: row;
  justify-content: start;
  align-items: center;
  font-size: 10px;
  height: 16%;
  gap: 16px;
  margin-bottom: 32px;
  & > img {
    width: auto;
    height: 50%;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    & > span {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

export const DivisorBar = styled.div`
  width: 4px;
  height: 20px;
  background-color: black;
  border-radius: 50px;
  @media (max-width: 990px) {
    display: none;
  }
`;

export const ImagenLogo = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 990px) {
    height: auto;
    margin: 0px auto;
  }
`;

export const ContainerInfoAndButton = styled.div`
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 990px) {
    align-items: center;
    padding: 0px 16px;
  }
`;

export const Titulo = styled.h1`
  width: 100%;
  font-family: "Lato";
  font-size: 44px;
  font-weight: 800;
  margin: 20px 0;
  color: #000;
  @media (max-width: 1440px) {
    font-size: 32px;
    width: 70%;
  }
  @media (max-width: 1200px) {
    font-size: 26px;
    width: 70%;
  }
  @media (max-width: 990px) {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  @media (max-width: 690px) {
    font-size: 24px;
  }
`;

export const Subtitulo = styled.h2`
  font-size: 34px;
  font-weight: 800;
  margin: 10px 0px;
  & > p {
    margin: 0px;
  }
  & > span {
    color: #63b66e;
  }
  @media (max-width: 1440px) {
    font-size: 22px;
    width: 70%;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    width: 70%;
  }
  @media (max-width: 990px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  @media (max-width: 690px) {
    font-size: 16px;
    width: 70%;
  }
`;

export const ContainerButton = styled(Button)`
  transform: skew(-30deg);
  color: white;
  font-size: 18px;
  font-weight: 700;
  background-color: #63b66e;
  border-radius: 0px;
  padding: 8px 16px;
  margin: 36px 0px;
  width: fit-content;
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  @media (max-width: 1440px) {
    font-size: 14px;
    padding: 8px 14px;
  }
  @media (max-width: 1200px) {
    font-size: 14px;
    padding: 8px 8px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    padding: 6px 10px;
  }
  @media (max-width: 690px) {
    font-size: 10px;
    padding: 8px 8px;
  }
`;
