import styled from "@emotion/styled";

export const Quote = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  color: ${(p) => (p.color === "green" ? "#000" : "#fff")};
  text-align: left;
  font-size: 2.5em;
  width: 80%;
  @media (max-width: 990px) {
    font-size: 1.5em;
    color: #000;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const Name = styled.p`
  color: ${(p) => (p.color === "green" ? "#000" : "#fff")};
  margin: 64px 0px 0px 0px;
  font-size: 1.3em;
  font-weight: bold;
  text-align: left;
  @media (max-width: 990px) {
    font-size: unset;
    color: #000;
  }
  @media (max-width: 560px) {
    width: 100%;
    margin: 32px 0px 0px 0px;
  }
`;

export const Role = styled.p`
  color: ${(p) => (p.color === "green" ? "#000" : "#fff")};
  margin: 8px 0px 0px 0px;
  font-size: 1.3em;
  text-align: left;
  width: 40%;
  @media (max-width: 990px) {
    font-size: unset;
    color: #000;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;
