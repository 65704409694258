import styled from "@emotion/styled";

export const Title = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  text-align: center;
  color: #fff;
  font-size: 3em;
  @media (max-width: 990px) {
    font-size: 2em;
  }
`;

export const Text = styled.p`
  color: #fff;
  margin: 16px 0px 0px 0px;
  text-align: center;
  font-size: 1.3em;
  @media (max-width: 990px) {
    font-size: unset;
  }
`;
