import React, { useState } from "react";
import {
  ArrowContainer,
  CardContainer,
  CardContent,
  ExpandenContent,
  ImageContainer,
  Image,
  Separator,
  ListItem,
  CardContentHeader,
} from "./styles";
import { South } from "@mui/icons-material";
import { Typography } from "@mui/material";

const DestinationCard = ({ title, subtitle, image, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleArrowClick = () => setIsOpen((prev) => !prev);

  return (
    <CardContainer>
      <Separator>
        <ImageContainer>
          <Image src={image} alt="destionation" />
        </ImageContainer>
        <div />
        <ArrowContainer onClick={handleArrowClick} isOpen={isOpen}>
          <South fontSize="large" />
        </ArrowContainer>
      </Separator>
      <CardContent isOpen={isOpen}>
        <CardContentHeader>
          <Typography variant="h5" style={{ color: "#285a9b" }} textAlign="center" fontWeight={600}>
            {title}
          </Typography>
          <Typography style={{ color: "#285a9b" }} textAlign="center">
            {subtitle}
          </Typography>
        </CardContentHeader>

        <ExpandenContent visible={isOpen}>
          {content.map((item) => (
            <ListItem>
              <Typography style={{ color: "#285a9b" }} lineHeight={1.2} fontWeight={700}>
                {item.title}
              </Typography>
              <Typography sx={{ whiteSpace: 'break-spaces'}} lineHeight={1.1} fontWeight={item.type === 'note' ? 700 : 500}>{item.body}</Typography>
            </ListItem>
          ))}
        </ExpandenContent>
      </CardContent>
    </CardContainer>
  );
};

export default DestinationCard;
