import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Title = styled(Typography)`
  color: white;
  text-align: left;
  font-weight: 800;

  @media (max-width: 560px) {
    font-size: 1rem;
  }
`;

export const CardHeader = styled.div`
  position: relative;
  padding: 16px 104px 16px 32px;
  background-color: #63b66e;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 8px;
    left: -8px;
    border: 1px solid #63b66e;
    @media (max-width: 560px) {
      width: 95%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 64px solid #e3ecf3;
    border-left: 88px solid transparent;
  }

  & svg path {
    fill: white;
  }

  @media (max-width: 560px) {
    max-width: 360px;
    padding-right: 80px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px 0;
`;

export const ContentItem = styled.a`
  margin: 8px 0;
  color: #63b66e;
  text-align: left;
`;
