import {
  ContainerHeader,
  ContainerLogos,
  ImagenHeader,
  Subtitulo,
  Titulo,
  ContainerButton,
  ContainerInfoAndButton,
  ImagenLogo,
  InfoHeader,
} from "./styles";
import React from "react";

const Header = () => {
  return (
    <ContainerHeader>
      <InfoHeader>
        <ContainerLogos>
          <span>ORGANIZAN:</span>
          <img src={"/assets/Logos/abappra-black.png"} alt="" />
          <img src={"/assets/Logos/felaban-black.png"} alt="" />
        </ContainerLogos>

        <ImagenLogo
          src={"/assets/Header/congreso.jpg"}
          alt="Imagen del encabezado"
        />
        <ContainerInfoAndButton>
          <Titulo>
            26 y 27 de Agosto 2024, Buenos Aires, Palacio Libertad (ex CCK)
          </Titulo>
          <Subtitulo>
            <p>Tu decisión.</p> <span>Nuestro futuro sostenible.</span>
          </Subtitulo>
          <ContainerButton
            href="https://registro.felaban.net/congreso/77"
            sx={{
              "&.MuiButton-root:hover": { backgroundColor: "transparent" },
            }}
          >
            <span>QUIERO SUSCRIBIRME</span>
          </ContainerButton>
        </ContainerInfoAndButton>
      </InfoHeader>
      <ImagenHeader>
        <img src={"/assets/Header/imgmundohome.jpg"} alt="" />
      </ImagenHeader>
    </ContainerHeader>
  );
};

export default Header;
