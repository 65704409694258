import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Imgbackground = styled.img`
  width: 90%;
  z-index: 0;
  object-fit: cover;
  object-position: bottom;
  padding: 0 5%;
`;

export const CtasContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 990px) {
    flex-direction: column;
    bottom: 8%;
    width: 100%;
    left: unset;
  }
`;

export const Button2 = styled(Button)`
  width: 180px;
  transform: skew(-30deg);
  color: white;
  font-weight: 800;
  letter-spacing: 0.5px;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 12px 16px;
  margin-right: 16px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
  @media (max-width: 1400px) {
    width: 140px;
    padding: 8px 8px;
    margin-right: 8px;
    font-size: 10px;
  }
  @media (max-width: 990px) {
    width: 180px;
    padding: 8px 8px;
    margin-right: 0;
    margin-bottom: 16px;
    font-size: 14px;
  }
  @media (max-width: 560px) {
    width: 140px;
    padding: 8px 8px;
    margin-right: 0;
    margin-bottom: 8px;
    font-size: 10px;
  }
`;

export const Button3 = styled(Button)`
  width: 240px;
  transform: skew(-30deg);
  color: #63b66e;
  font-weight: 800;
  letter-spacing: 0.5px;
  border-radius: 0px;
  border: 4px solid #63b66e;
  background-color: transparent;
  padding: 8px 16px;
  margin-left: 16px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
  @media (max-width: 1400px) {
    width: 180px;
    padding: 4px 8px;
    margin-left: 8px;
    font-size: 10px;
  }
  @media (max-width: 990px) {
    width: 240px;
    padding: 4px 8px;
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;
  }
  @media (max-width: 560px) {
    width: 200px;
    padding: 4px 8px;
    margin-left: 0;
    margin-top: 8px;
    font-size: 10px;
  }
`;
