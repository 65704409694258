import { Box, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import HotelCard from "./components/HotelCard/HotelCard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Backdrop, InfoContainer, EmptySlot } from "./styles";

const cards = [
  {
    title: "Hotel Hilton Buenos Aires",
    stars: 5,
    address: "Blvd. Macacha Guemes 351, CABA",
    distance: "700 metros (10 minutos) a pie, 2,2 KM (8 minutos) en auto",
    url: "https://www.hilton.com/en/hotels/buehihh-hilton-buenos-aires/?SEO_id=BING-AMER-HH-BUEHIHH&y_source=1_MTIxNjk0OTItNDgzLWxvY2F0aW9uLndlYnNpdGU%3D",
    image: "/assets/Hotels/hilton.png",
  },
  {
    title: "Novotel Buenos Aires",
    stars: 4,
    address: "Av. Corrientes 1334, CABA",
    distance: "1,6 KM (23 minutos) a pie, 2,4 KM (10 minutos) en auto",
    url: "mailto:H6503-RE@accor.com",
    image: "/assets/Hotels/novotel.png",
  },
  {
    title: "Ibis Obelisco",
    stars: 3,
    address: "Av. Corrientes 1344 Esq. Talcahuano, CABA",
    distance: "1,6 KM (23 minutos) a pie, 2,4 KM (10 minutos) en auto",
    url: "mailto:H6502-RE@accor.com",
    image: "/assets/Hotels/ibis-obelisco.png",
  },
  {
    title: "Ibis Style",
    stars: 3,
    address: "Av. Corrientes 632, CABA",
    distance: "700 metros (10 minutos) a pie, 1,9 KM (8 minutos) en auto",
    url: "mailto:Emanuel.GOMEZ@accor.com",
    image: "/assets/Hotels/ibis-style.png",
  },
  {
    title: "Hotel Emperador Buenos Aires",
    stars: 5,
    address: "Av. del Libertador 420, CABA",
    distance: "2 KM (28 minutos) a pie, 2,8 KM (10 minutos) en auto",
    url: "https://www.hotel-emperador.com.ar/",
    image: "/assets/Hotels/emperador.png",
    code: (
      <>
        Código de reserva: <strong>SUSTENTABLE</strong>
      </>
    ),
  },
];

const BuenosAiresSection = () => {
  const iconRef = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setOpen] = useState(false);

  const showInfo = () => {
    setOpen(true);
    const rect = iconRef.current?.getBoundingClientRect();
    if (rect) {
      setPosition({
        x: window.innerWidth - rect.x - rect.width,
        y: rect.bottom + 4,
      });
    }
    document.body.style.overflow = "hidden";
  };

  const closeInfo = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      sx={{
        my: 4,
        px: { xs: 2, sm: 4 },
      }}
      id="argentina"
    >
      <img
        src={"assets/Logos/congreso-small-green.png"}
        alt="Logo del congreso"
        width="60"
      />
      <Box
        mt={2}
        display="flex"
        width="100%"
        gap={2}
        alignItems="flex-start"
        maxWidth={900}
      >
        <EmptySlot />
        <Typography
          variant="h5"
          fontWeight={800}
          sx={{ flex: 1 }}
          textAlign="center"
        >
          HOTELES RECOMENDADOS Y TARIFAS ESPECIALES
        </Typography>
        {/* <InfoOutlinedIcon
          ref={iconRef}
          sx={{ fill: "#64b76f", mt: -0.5, cursor: "pointer" }}
          fontSize="large"
          onClick={showInfo}
        /> */}
      </Box>

      <Typography
        variant="body1"
        fontWeight={700}
        style={{ textWrap: "pretty" }}
        lineHeight={1.2}
        maxWidth={600}
      >
        Para vivir una experiencia inigualable, te acercamos hoteles ubicados a
        pocos pasos del majestuoso Palacio Libertad, sede del Congreso.
        Aprovechá las tarifas especiales diseñadas exclusivamente para los
        asistentes.
        <br />
        <br />
        ¡No pierdas la oportunidad de reservar tu estadía con tu hotel favorito
        y disfrutá de la experiencia completa!
      </Typography>
      <Grid container flexDirection="column" maxWidth={900} sx={{ mt: 6 }}>
        {cards.map((card, i) => (
          <HotelCard {...card} direction={i % 2 !== 0 ? "normal" : "reverse"} />
        ))}
      </Grid>

      {/* {isOpen && (
        <Backdrop onClick={closeInfo}>
          <InfoContainer
            position={position}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              textAlign="left"
              fontWeight={800}
              lineHeight={1.1}
              style={{ marginBottom: 4 }}
            >
              Nota:
            </Typography>
            <Typography textAlign="left" fontWeight={500} lineHeight={1.1}>
              El contacto puede hacerse directamente con el hotel o bien
              enviando un email a{" "}
              <a href="mailto:congreso@abappra.org.ar">
                congreso@abappra.org.ar
              </a>
            </Typography>
            <Typography
              style={{ marginTop: 4 }}
              textAlign="left"
              fontWeight={500}
              lineHeight={1.1}
            >
              REF: HOTELERÍA
            </Typography>
          </InfoContainer>
        </Backdrop>
      )} */}
    </Grid>
  );
};

export default BuenosAiresSection;
