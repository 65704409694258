import { Alert, ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import BenefitsSection from "./components/BenefitsSection/BenefitsSection";
import CountdownSection from "./components/CountdownSection/CountdownSection";
import DiscoverSection from "./components/DiscoverSection/DiscoverSection";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HighlightsSection from "./components/HighlightsSection/HighlightsSection";
import InstitutionalSection from "./components/InstitutionalSection/InstitutionalSection";
import LecturersSection from "./components/LecturersSection/LecturersSection";
import Navbar from "./components/Navbar/Navbar";
import PhrasesSection from "./components/PhrasesSection/PhrasesSection";
import RoomsSection from "./components/RoomsSection/RoomsSection";
import SponsorshipsSection from "./components/SponsorshipsSection/SponsorshipsSection";
import ThemesSection from "./components/ThemesSection/ThemesSection";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import BuenosAiresSection from "./components/BuenosAiresSection/BuenosAiresSection";
import ArgentinaSection from "./components/ArgentinaSection/ArgentinaSection";
import Banner from "./components/Banner/Banner";
import PressSection from "./components/Press/PressSection";
import Popup from "./components/Popup/Popup";

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: [
      "Lato",
      "Bebas Neue",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const hash = window.location.hash;
    const ancla = hash.startsWith("#") ? hash.slice(1) : hash;
    if (ancla) {
      const elementToScroll = document.getElementById(ancla);
      if (elementToScroll)
        elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Popup isOpen={open} setOpen={setOpen} />
      <Navbar />
      <Header />
      <CountdownSection setSnackbar={setSnackbar} />
      <Banner />
      <DiscoverSection />
      <ThemesSection />
      {/* <PhrasesSection /> */}
      {/* <LecturersSection /> */}
      <BenefitsSection />
      <InstitutionalSection />
      {/* <HighlightsSection /> */}
      <SponsorshipsSection />
      <RoomsSection />
      <BuenosAiresSection />
      <ArgentinaSection />
      <PressSection />
      <Footer setSnackbar={setSnackbar} />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() =>
            setSnackbar((prevState) => ({ ...prevState, open: false }))
          }
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
