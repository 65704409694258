import { Grid, Typography } from "@mui/material";
import React from "react";
import DiscoverCard from "./components/DiscoverCard/DiscoverCard";

const cards = [
  {
    title: "Objetivos",
    body: "El encuentro representa un punto de referencia para explorar la evolución global en finanzas sostenibles, fomentar el intercambio de conocimientos y oportunidades de negocios para actores del sector financiero y los stakeholders de Latinoamérica.",
  },
  {
    title: "Descripción",
    body: "Las jornadas ofrecen charlas magistrales, workshops, actividades interactivas, networking de negocios, certamen y premiación para empresas comprometidas con el desarrollo sostenible.",
  },
  {
    title: "A quien va dirigido",
    body: "Representantes de organismos gubernamentales locales e internacionales. Profesionales y líderes del sector financiero. Referentes y CEOs de las principales corporaciones. Organizaciones no gubernamentales y entidades de desarrollo. Referentes de tecnología financiera (fintech) y start ups. Académicos y expertos en banca y sustentabilidad.",
  },
];

const DiscoverSection = () => {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      sx={{
        my: 4,
        px: { xs: 2, sm: 4 },
        // background:
        //   "url('/assets/Discover/background.jpg') 100% / 100% no-repeat",
      }}
    >
      <img
        src={"assets/Logos/congreso-small-green.png"}
        alt="Logo del congreso"
        width="60"
      />
      <Typography variant="h5" fontWeight={800} sx={{ my: 2 }}>
        II CONGRESO LATINOAMERICANO DE BANCA SOSTENIBLE E INCLUSIVA
      </Typography>
      <Typography
        variant="body1"
        fontWeight={700}
        style={{ textWrap: "pretty" }}
        lineHeight={1.2}
        maxWidth={600}
      >
        Descubrí por qué la sustentabilidad es un buen negocio en el 2do
        Congreso Latinoamericano de Banca Sostenible e Inclusiva, seleccionado
        para realizarse en Argentina por su liderazgo en iniciativas de economía
        sustentable, los días 26 y 27 de Agosto de 2024 en el Palacio Libertad
        (ex CCK). <br /> Organizan ABAPPRA y FELABAN.
      </Typography>
      <Grid container flexDirection="column" maxWidth={700} sx={{ mt: 6 }}>
        {cards.map((card, idx) => (
          <DiscoverCard {...card} number={idx + 1} />
        ))}
      </Grid>
    </Grid>
  );
};

export default DiscoverSection;
