import React, { useState, useEffect } from "react";
import {
  Button2,
  Container,
  ContainerCount,
  ContainerCountDown,
  ContainerDateInfo,
  Form,
  Input,
  Number,
  Numbers,
  Type,
} from "./styles";

import dayjs from "dayjs";
import { Typography } from "@mui/material";

const calculateTimeLeft = (targetDate) => {
  const now = dayjs();
  const difference = dayjs(targetDate).diff(now);
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const CountdownSection = ({ setSnackbar }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch("/back/public/api/enviar_email_sponsor", {
      method: "POST",
      body: formData,
    });
    if (response.status < 400) {
      setSnackbar({
        open: true,
        severity: "success",
        message: "Contacto enviado exitosamente.",
      });
      setEmail("");
    } else
      setSnackbar({
        open: true,
        severity: "danger",
        message: "Ha ocurrido un error. Intente nuevamente más tarde.",
      });
  };

  const targetDate = "2024-08-26T23:00:00";
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <>
      <Container>
        <img src={"/assets/CountDown/bgcountdown.jpg"} alt="" />

        <ContainerCountDown>
          <img
            src={"assets/Logos/congreso-small-green.png"}
            alt="Logo del congreso"
            width="60"
          />
          <Typography variant="h5" fontWeight={800} sx={{ my: 2 }}>
            TODAVÍA ESTÁS A TIEMPO DE CONVERTIRTE EN PATROCINADOR DEL CAMBIO
          </Typography>

          <ContainerCount>
            <ContainerDateInfo>
              <Numbers>
                <Number>
                  {timeLeft.days.toString().length === 1
                    ? "0"
                    : timeLeft.days.toString().charAt(0)}
                </Number>
                <Number>
                  {timeLeft.days.toString().length === 2
                    ? timeLeft.days.toString().charAt(1)
                    : timeLeft.days.toString().charAt(0)}
                </Number>
              </Numbers>
              <Type>Días</Type>
            </ContainerDateInfo>
            <ContainerDateInfo>
              <Numbers>
                <Number>
                  {timeLeft.hours.toString().length === 1
                    ? "0"
                    : timeLeft.hours.toString().charAt(0)}
                </Number>
                <Number>
                  {timeLeft.hours.toString().length === 2
                    ? timeLeft.hours.toString().charAt(1)
                    : timeLeft.hours.toString().charAt(0)}
                </Number>
              </Numbers>
              <Type>Horas</Type>
            </ContainerDateInfo>
            <ContainerDateInfo>
              <Numbers>
                <Number>
                  {timeLeft.minutes.toString().length === 1
                    ? "0"
                    : timeLeft.minutes.toString().charAt(0)}
                </Number>
                <Number>
                  {timeLeft.minutes.toString().length === 2
                    ? timeLeft.minutes.toString().charAt(1)
                    : timeLeft.minutes.toString().charAt(0)}
                </Number>
              </Numbers>
              <Type>Minutos</Type>
            </ContainerDateInfo>
          </ContainerCount>

          <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button2 type="submit">
              <span>QUIERO SER SPONSOR</span>
            </Button2>
          </Form>
        </ContainerCountDown>
      </Container>
    </>
  );
};

export default CountdownSection;
