import styled from "@emotion/styled";
import { DialogContent } from "@mui/material";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e3ecf3;
  width: 800px;
`;

export const Title = styled.h2`
  color: #285a9b;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  color: #285a9b;
  text-align: center;
  width: 200px;
  p {
    margin: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  background-color: #7edbaa;
  padding: 8px 0;
`;

export const CardItem = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  flex-grow: 1;
  justify-content: center;
`;

export const Content = styled(DialogContent)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 0;
  overflow: hidden;
  @media (max-width: 990px) {
    img {
      width: 300px;
      height: 375px;
    }
  }
`;
