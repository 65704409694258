import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  background-color: #64b76f;
  height: 100%;
  max-width: 60vw;
  display: grid;
  overflow: hidden;
  @media (max-width: 560px) {
    max-width: 80vw;
  }
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    border-top: 64px solid #fff;
    border-left: 64px solid transparent;
    z-index: 1;
  }
`;

export const Name = styled.h4`
  font-weight: 700;
  text-align: center;
  color: #fff;
  font-size: 1.5em;
  width: 80%;
  margin: 40px auto 16px auto;
`;

export const Description = styled.p`
  /* font-weight: 700; */
  font-size: 1.3em;
  text-align: center;
  width: 80%;
  margin: 0 auto 32px auto;
  color: #fff;
`;

export const Person = styled.img`
  width: 100%;
  max-height: 50vh;

  /* height: 80%; */
  object-fit: contain;
  z-index: 3;
  align-self: end;
`;

export const Background = styled.div`
  position: absolute;
  bottom: -1px;
  height: 40%;
  width: 100%;
  background-color: #fff;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    height: 200px;
    top: -100px;
    left: -80px;
    /* border-bottom: 160px solid #fff;
    border-left: 200px solid transparent; */
    width: 160%;
    background-color: #fff;
    transform: rotate(-24deg);
    z-index: 2;
  }
`;
