import styled from "@emotion/styled";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.75);
`;

export const InfoContainer = styled.div`
  max-width: 540px;
  width: calc(100vw - 32px);
  background-color: #bbdaba;
  padding: 24px;
  box-sizing: border-box;
  position: absolute;
  top: ${(p) => p.position.y}px;
  right: ${(p) => p.position.x}px;
`;

export const EmptySlot = styled.div`
  width: 35px;
  height: 35px;
`
