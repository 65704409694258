import styled from "@emotion/styled";

export const Title = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  text-align: center;
  color: #fff;
`;

export const Subtitle = styled.h4`
  font-weight: 700;
  font-size: 1.3em;
  text-align: center;
  margin: 8px 0 0 0;
  color: #fff;
`;
