import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const GridContainer = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 32px 0;

  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  color: #fff;
  margin: 16px 0px 0px 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  /* display: flex; */
  /* flex-direction: ; */
  position: relative;
`;

export const Button2 = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 150%;
  width: max-content;
  transform: skew(-30deg);
  color: #63b66e;
  font-weight: 800;
  letter-spacing: 0.5px;
  border-radius: 0px;
  background-color: #fff;
  padding: 4px 16px;
  &:hover {
    background-color: rgba(225, 225, 225, 0.8) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
  @media (max-width: 1400px) {
    font-size: 13px;
  }
  @media (max-width: 990px) {
    position: initial;
    margin: 16px 0;
    font-size: 14px;
  }
  @media (max-width: 560px) {
    /* font-size: 14px; */
  }
`;
