import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  LogoContainer,
  NavbarButton,
  NavbarButtonContent,
  StyledLink,
} from "./styled";

const sections = [
  {
    name: "Inicio",
    url: "#",
  },
  {
    name: "Preventa",
    url: "https://registro.felaban.net/congreso/77",
    newTab: true,
  },
  {
    name: "Agenda",
    url: "#agenda",
  },
  // {
  //   name: "Conferencistas",
  //   url: "#conferencistas",
  // },
  {
    name: "Sponsors",
    url: "#sponsors",
  },
  {
    name: "Sede",
    url: "#sede",
  },
  {
    name: "Prensa",
    url: "#press",
  },
  {
    name: "Concurso",
    url: "#concurso",
  },
];

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(window.scrollY > 10);

  useEffect(() => {
    const onScroll = () => {
      setShowLogo(window.scrollY > 200);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {sections.map((section) => (
          <ListItem key={section.url} disablePadding>
            <ListItemButton
              href={section.url}
              {...(section.newTab && {
                target: "_blank",
                rel: "noreferrer",
              })}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={section.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem key={"#argentina"} disablePadding>
          <ListItemButton href={"#argentina"} sx={{ textAlign: "center" }}>
            <ListItemText>
              Yo <span style={{ color: "red", fontSize: "x-large" }}>♥</span>{" "}
              Argentina
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        component="nav"
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#7edbaa",
          height: "80px",
          boxShadow:
            "0px 1px 2px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Container
          sx={{
            display: { lg: "flex" },
            justifyContent: { xs: "flex-end", md: "space-between" },
            rowGap: "36px",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              paddingLeft: "0px",
              width: "calc(100% - 24px)",
            }}
          >
            <Box
              sx={{
                // flexGrow: 1,
                display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="default"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <LogoContainer
              alignSelf="start"
              sx={{
                transition: "opacity 0.5s",
                opacity: showLogo ? 1 : 0,
              }}
            >
              <img
                src="/assets/Logos/congreso.png"
                style={{ width: "260px", maxWidth: "100%" }}
                alt="Logo"
              />
            </LogoContainer>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: "24px",
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                alignItems: "center",
              }}
            >
              <>
                {sections.map((section) => (
                  <StyledLink
                    key={section.url}
                    href={section.url}
                    {...(section.newTab && {
                      target: "_blank",
                      rel: "noreferrer",
                    })}
                    color={"#000"}
                    style={{
                      fontWeight: "700",
                    }}
                    sx={{
                      display: { xs: "block", md: "none", lg: "flex" },
                      m: 2,
                    }}
                  >
                    {section.name}
                  </StyledLink>
                ))}
              </>
            </Box>

            <NavbarButton
              sx={{
                display: {
                  xs: "none",
                  md: "inherit",
                },
              }}
              href="#argentina"
            >
              <NavbarButtonContent>
                YO <span style={{ color: "red", fontSize: "x-large" }}>♥</span>{" "}
                ARGENTINA
              </NavbarButtonContent>
            </NavbarButton>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
              backgroundColor: "#7edbaa",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Navbar;
