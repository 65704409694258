import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ContainerFormAndMaps = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  margin-bottom: 20px;
  gap: 16px;
  z-index: 0;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 0px;
    padding: 0px 0px;
    align-items: center;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 990px) {
    align-items: center;
  }
`;

export const ImgContact = styled.img`
  width: fit-content;
  height: 90%;
  position: absolute;
  top: -20px;
  left: 0px;
  z-index: -1;
  width: 100%;
  @media (max-width: 990px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-top: 36px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 380px;
  @media (max-width: 990px) {
    align-items: center;
    min-width: 380px;
  }
  @media (max-width: 576px) {
    min-width: 320px;
    width: auto;
    max-width: 380px;
  }
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #63b66e;
  @media (max-width: 990px) {
    width: 350px;
  }
  @media (max-width: 576px) {
    min-width: 320px;
    width: auto;
    max-width: 350px;
  }
`;

export const TextArea = styled.textarea`
  height: 116px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #63b66e;
  resize: none;
  @media (max-width: 990px) {
    width: 350px;
  }
  @media (max-width: 576px) {
    min-width: 320px;
    width: auto;
    max-width: 350px;
  }
`;

export const Button2 = styled(Button)`
  width: 180px;
  transform: skew(-30deg);
  color: white;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 8px 16px;
  margin: 4px 0px 32px 12px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
  & > span {
    display: inline-block;
    transform: skew(30deg);
  }
`;

export const ContainerMaps = styled.div`
  height: 300px;
  max-width: 380px;
  width: 100%;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #7edbaa;
  height: 350px;
  padding: 32px 22%;

  & > img {
    width: 60%;
    height: auto;
  }
  @media (max-width: 990px) {
    height: 100%;
    padding: 32px 12%;
  }
`;

export const DivisorBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: black;
  border-radius: 50px;
  margin-top: 16px;
`;

export const ImgLogoFooter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-left: 32px;
  & :first-child {
    width: 160px;
    height: auto;
    min-width: 160px;
    min-height: auto;
  }
  & :nth-child(2) {
    width: 76px;
    height: auto;
    min-width: 76px;
    min-height: auto;
  }

  @media (max-width: 990px) {
    padding-left: 0px;
  }
`;

export const Nav = styled.a`
  display: flex;
  margin-bottom: 12px;
  color: black;
  font-size: 16px;
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: underline solid #000;
  }
`;

export const ImgFooter = styled.img`
  //min-width: 460px;
  height: auto;
`;
