import React from "react";
import {
  Container,
  ContainerText,
  Content,
  Imgbackground,
  SubTitle,
  Text,
  Title,
} from "./styles";

const textos = [
  {
    title: "Conferencias magistrales:",
    body: "Participar en dos jornadas de conferencias y paneles de discusión de alto nivel con la posibilidad de formular preguntas a los expositores en sesiones en vivo, y con la grabación de las mismas disponible durante un mes después del evento.",
  },
  {
    title: "Networking:",
    body: "Nuestro congreso se destaca como una plataforma exclusiva para reunirse con colegas, CEOs, presidentes de corporaciones, referentes nacionales e internacionales, en un entorno diseñado para fomentar los negocios, el intercambio de visiones, conocimientos y experiencias, el congreso se convierte en el escenario ideal para la innovación y el crecimiento empresarial.",
  },
  {
    title: "Certificado digital de participación",
  },
  {
    title:
      "Acceso a las conferencias grabadas durante tres (3) meses (después de finalizado el congreso)",
  },
  {
    title: "Kit de participante",
  },
  {
    title: "Coffees",
  },
  {
    title: "Ágape de bienvenida",
  },
  {
    title: "Cocktail vip clausura",
  },
];

const BenefitsSection = () => {
  return (
    <Container>
      <Imgbackground src={"/assets/Benefits/bgbeneficios.jpg"} alt="" />
      <Content>
        <ContainerText>
          <img src="/assets/Logos/congreso-small-green.png" alt="" />
          <Title>BENEFICIOS EXCLUSIVOS DEL CONGRESO</Title>
          {textos.map(({ title, body }) => (
            <>
              <SubTitle>{title}</SubTitle>
              <Text>{body}</Text>
            </>
          ))}
        </ContainerText>
      </Content>
    </Container>
  );
};

export default BenefitsSection;
