import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  RoomOutlined as RoomOutlinedIcon,
  LanguageOutlined as LanguageOutlinedIcon,
  SignpostOutlined as SignpostOutlinedIcon,
  PercentOutlined as PercentOutlinedIcon,
} from "@mui/icons-material";
import {
  Title,
  CardContent,
  CardHeader,
  CardContainer,
  Content,
  TrapezoidBackground,
  TrapezoidBorder,
  BookButton,
  Wrapper,
  Image,
  ImageContainer,
  ListItem,
  Link,
  StarsContainer,
  TrapezoidBorderReverse,
  TrapezoidBackgroundReverse,
} from "./styles";
import { Typography } from "@mui/material";

const HotelCard = ({
  title,
  stars,
  address,
  distance,
  url,
  image,
  direction,
  code,
}) => {
  const containerRef = useRef();
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 600);
    if (containerRef.current && height !== containerRef.current.clientHeight) {
      setHeight(containerRef.current.clientHeight);
    }
  }, [height]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const size = isMobile ? 110 : height;

  const Stars = useMemo(
    () =>
      [1, 2, 3, 4, 5].map((i) =>
        i <= stars ? (
          <StarIcon fontSize="small" />
        ) : (
          <StarBorderIcon fontSize="small" />
        )
      ),
    [stars]
  );

  return (
    <CardContainer spacing={direction === "reverse" ? 0 : 16}>
      {!isMobile &&
        (direction === "reverse" ? (
          <>
            <TrapezoidBorderReverse height={size} spacing={16} />
            <TrapezoidBackgroundReverse height={size} spacing={16} />
          </>
        ) : (
          <>
            <TrapezoidBorder height={size} spacing={16} />
            <TrapezoidBackground height={size} spacing={16} />
          </>
        ))}
      <Wrapper ref={containerRef} direction={direction}>
        <ImageContainer>
          <Image src={image} alt="hotel-image" />
          <BookButton href={url} target="_blank" rel="noreferrer">
            <span>RESERVA ACÁ</span>
          </BookButton>
        </ImageContainer>
        <Content>
          <CardHeader>
            <Title variant="h5" fontWeight={600}>
              {title}
            </Title>
            <StarsContainer>{Stars}</StarsContainer>
          </CardHeader>
          <CardContent>
            <ListItem>
              <RoomOutlinedIcon fontSize="small" />
              <Typography variant="body2" textAlign="left" fontWeight={500}>
                {address}
              </Typography>
            </ListItem>
            <ListItem>
              <SignpostOutlinedIcon fontSize="small" />
              <Typography variant="body2" textAlign="left" fontWeight={500}>
                {distance}
              </Typography>
            </ListItem>
            {code && (
              <ListItem>
                <PercentOutlinedIcon fontSize="small" />
                <Typography variant="body2" textAlign="left" fontWeight={500}>
                  {code}
                </Typography>
              </ListItem>
            )}
            <ListItem>
              <LanguageOutlinedIcon fontSize="small" />
              <Typography variant="body2" textAlign="left" fontWeight={500}>
                Para más información,
                <Link href={url} target="_blank" rel="noreferrer">
                  comunicarse con el hotel
                </Link>
              </Typography>
            </ListItem>
          </CardContent>
        </Content>
      </Wrapper>
    </CardContainer>
  );
};

export default HotelCard;
