import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import "swiper/css";
import "swiper/css/autoplay";
import "./index.css";
import { Text, Title } from "./styles";

const rooms = [
  {
    url: "/assets/Rooms/auditorio-palacio-libertad.jpeg",
    title: "AUDITORIO NACIONAL",
    subtitle: "",
  },
  {
    url: "/assets/Rooms/salon-de-honor.jpeg",
    title: "SALÓN DE HONOR",
    subtitle: "El espacio contará con paneles corporativos y workshops.",
  },
  {
    url: "/assets/Rooms/salon-de-los-escudos.jpg",
    title: "SALÓN DE LOS ESCUDOS",
    subtitle:
      "Este espacio ubicado en el 2do. piso, es el conector entre ambos salones, donde se realizará product placement y diferentes experiencias de marca.",
  },
  {
    url: "/assets/Rooms/plaza-del-centro.jpg",
    title: "PLAZA DEL CENTRO",
    subtitle: "(Bajo la Ballena) Activaciones de marcas y stands.",
  },
];

const NextButton = () => {
  const swiper = useSwiper();
  return (
    <Button
      onClick={() => {
        swiper.slideNext();
      }}
      className="next-button"
    >
      <img
        src="/assets/misc/rigth-arrow.svg"
        alt=""
        style={{ width: "100%", height: "16px" }}
      />
    </Button>
  );
};

const RoomsSection = () => {
  return (
    <Grid container className="rooms">
      <Swiper
        modules={[Autoplay]}
        direction="horizontal"
        loop={true}
        autoplay={{
          delay: 10000,
        }}
      >
        {rooms.map(({ url, title, subtitle }) => (
          <SwiperSlide>
            <div className="slide-container">
              <div className="content">
                <Title>{title}</Title>
                <Text>{subtitle}</Text>
                <NextButton />
              </div>
              <img src={url} alt={title} />
              <div className="overlay" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );
};

export default RoomsSection;
