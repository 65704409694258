import React, { useState } from "react";
import {
  ArrowContainer,
  CardContainer,
  CardContent,
  CardHeader,
  LineDivider,
  Separator,
  Text,
} from "./styles";
import { South } from "@mui/icons-material";
import { Typography } from "@mui/material";

const ThemeCard = ({ title, time, textContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleArrowClick = () => setIsOpen((prev) => !prev);

  return (
    <CardContainer>
      <CardHeader>
        <Typography
          variant="h5"
          color="white"
          fontWeight={700}
          textAlign="center"
        >
          {title}
        </Typography>
      </CardHeader>
      <Separator>
        <div />
        <div />
        <LineDivider />
        <ArrowContainer onClick={handleArrowClick} isOpen={isOpen}>
          <South fontSize="large" />
        </ArrowContainer>
      </Separator>
      <CardContent isOpen={isOpen}>
        {/* <Text
          visible={!isOpen}
          variant="h6"
          style={{ color: "#285a9b" }}
          lineHeight={1.2}
          textAlign="center"
          fontWeight={800}
        >
          HORARIOS
        </Text>
        <Text visible={!isOpen} variant="h6" fontWeight={800} lineHeight={1.2} textAlign="center">
          {time}
        </Text> */}
        <Text visible={isOpen} textAlign="left" fontWeight={600}>
          {textContent}
        </Text>
      </CardContent>
    </CardContainer>
  );
};

export default ThemeCard;
