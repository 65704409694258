import React, { useState } from "react";
import {
  Button2,
  ContainerFooter,
  ContainerForm,
  ContainerFormAndMaps,
  DivisorBar,
  Form,
  ImgContact,
  Input,
  TextArea,
  Title,
  ImgFooter,
  ImgLogoFooter,
  Nav,
  ContainerMaps,
} from "./styled";
import { Grid } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = ({ setSnackbar }) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("company", company);
    formData.append("email", email);
    formData.append("message", message);
    const response = await fetch("/back/public/api/enviar_email", {
      method: "POST",
      body: formData,
    });
    if (response.status < 400) {
      setSnackbar({
        open: true,
        severity: "success",
        message: "Mensaje enviado exitosamente.",
      });
      setName("");
      setCompany("");
      setEmail("");
      setMessage("");
    } else
      setSnackbar({
        open: true,
        severity: "danger",
        message: "Ha ocurrido un error. Intente nuevamente más tarde.",
      });
  };

  return (
    <>
      <ContainerFormAndMaps id="sede">
        <ImgContact src="/assets/Footer/bg-contacto.jpg" alt="" />
        <ContainerForm>
          <Title>¡Contactanos!</Title>
          <Form
            onSubmit={handleSubmit}
            action="forms/contact.php"
            method="post"
            role="form"
            className="php-email-form"
          >
            <Input
              type="text"
              placeholder="Nombre y Apellido"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Empresa"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextArea
              placeholder="Mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Button2 type="submit">
              <span>Enviar</span>
            </Button2>
          </Form>
        </ContainerForm>

        <ContainerMaps>
          <iframe
            title="Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.021910495085!2d-58.37230402341762!3d-34.60360745749382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3353283598701%3A0xff14e7edde2f5152!2sCentro%20Cultural%20Kirchner%20(CCK)!5e0!3m2!1sen!2sar!4v1717612913541!5m2!1sen!2sar"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </ContainerMaps>
      </ContainerFormAndMaps>
      <ContainerFooter>
        <ImgFooter src={"/assets/Logos/congreso.png"} alt="" />
        <Grid
          container
          direction={"row"}
          spacing={1}
          mt={1}
          mb={2}
          columnGap={"0px"}
        >
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Nav href="# ">Inicio</Nav>
            <Nav
              href="https://registro.felaban.net/congreso/77"
              target="_blank"
              rel="noreferrer"
            >
              Preventa
            </Nav>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            {/* <Nav href="#conferencistas">Conferencistas</Nav> */}
            <Nav href="#sponsors">Sponsors</Nav>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <span>Atención personalizada</span>
            <br />
            <br />
            <span>ABAPPRA: </span>
            <br />
            <a href="mailto:congreso@abappra.org.ar">congreso@abappra.org.ar</a>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <ImgLogoFooter>
              <img src={"/assets/Logos/abappra-black.png"} alt="" />

              <img src={"/assets/Logos/felaban-black.png"} alt="" />
            </ImgLogoFooter>
          </Grid>
        </Grid>
        <Grid>
          <a
            href="https://www.linkedin.com/company/abappra/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000", marginRight: "16px" }}
          >
            <LinkedInIcon fontSize="large"></LinkedInIcon>
          </a>
          <a
            href="https://www.instagram.com/sustentablebsas"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#000" }}
          >
            <InstagramIcon fontSize="large"></InstagramIcon>
          </a>
        </Grid>
        <DivisorBar></DivisorBar>
      </ContainerFooter>
    </>
  );
};

export default Footer;
