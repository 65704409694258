import React from "react";
import {
  Container,
  Imgbackground,
  CtasContainer,
  Button2,
  Button3,
} from "./styles";
import { useResponsive } from "../../hooks/useResponsive";

const Banner = () => {
  const { width } = useResponsive();

  return (
    <Container id="concurso">
      <Imgbackground
        src={
          width > 990
            ? "/assets/Banner/banner_desktop.jpg"
            : "/assets/Banner/banner_mobile.jpg"
        }
        alt=""
      />
      <CtasContainer>
        <Button2 href="mailto:masfuturo2024@gmail.com">
          <span>ESCRIBINOS ACÁ</span>
        </Button2>
        <Button3
          href="/assets/Banner/Bases y condiciones Concurso impacto Positivo.pdf"
          target="_blank"
        >
          <span>VER BASES Y CONDICIONES</span>
        </Button3>
      </CtasContainer>
    </Container>
  );
};

export default Banner;
