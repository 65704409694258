import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const CardContainer = styled.div`
  position: relative;
  outline: 1px solid #285a9b;
  margin-bottom: 48px;
  z-index: 1;

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  background-color: white;
  z-index: 1;
`;


export const Title = styled(Typography)`
  color: white;
  text-align: left;
  font-weight: 800;
`;

export const CardHeader = styled.div`
  padding: 8px 16px;
  background-color: #285a9b;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  & > h5 {
    width: calc(100% - ${(p) => p.triangleWidth}px);
  }

  & svg path {
    fill: white;
  }
`;

export const CardContent = styled.div`
  padding: 16px;
  width: 75%;
  z-index: 1;
`;


export const CardNumberContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const CardNumber = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 100;
  color: #285a9b;
`;
export const CardNumberBorder = styled.div`
  position: absolute;
  top: 1px;
  right: 0px;
  z-index: 99;

  border-top: ${(props) => props.height}px solid #285a9b;
  border-left: ${(props) => props.height}px solid transparent;
`;

export const CardNumberBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;

  border-top: ${(props) => props.height}px solid white;
  border-left: ${(props) => props.height}px solid transparent;
`;

export const TrapezoidBackground = styled.div`
  position: absolute;
  left: ${(props) => -1 * (props.spacing - 1)}px;
  top: ${(props) => props.spacing + 1}px;
  border-bottom: ${(props) => props.height - 2}px solid #fff;
  border-right: ${(props) => props.height - 2}px solid transparent;
  width: calc(100% - ${(props) => props.height - 2}px);
  z-index: -1;
`;

export const TrapezoidBorder = styled.div`
  position: absolute;
  left: ${(props) => -1 * props.spacing}px;
  top: ${(props) => props.spacing}px;
  border-bottom: ${(props) => props.height}px solid #285a9b;
  border-right: ${(props) => props.height}px solid transparent;
  width: calc(100% - ${(props) => props.height - 3}px);
  z-index: -1;
`;
