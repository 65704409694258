import { Grid } from "@mui/material";
import React from "react";
import ThemeCard from "./components/ThemeCard/ThemeCard";
import { Button2, GridContainer, Title, TitleContainer } from "./styles";

const items = [
  {
    title: "BANCA SOSTENIBLE",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "El rol de los bancos de desarrollo para promover una banca más sustentable. Estrategias de negocios sustentables. Estrategias nacionales defnanzas sostenibles. Mesas de finanzas en América Latina.",
  },
  {
    title: "INSTRUMENTOS FINANCIEROS TEMÁTICOS",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "Financiando la transición energética: Casos de éxito y alianzas público – privadas para promover el desarrollo de energías limpias. Desarrollo sostenible en sector agropecuario y ganadero. Evaluación de riesgos ambientales y sociales.",
  },
  {
    title: "SERVICIOS ECOSISTÉMICOS",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "El rol de la biodiversidad en la economía y finanzas modernas. Bonos verdes, buenas prácticas y ejemplos de aplicabilidad en América Latina.",
  },
  {
    title: "REGULACIONES Y GOBERNANZA",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "NIIF de sustentabilidad. Avances en la taxonomía regional y etiquetado de cartera. Gobernanza para la sostenibilidad, participación de comunidades y alianzas estratégicas. Alianzas globales para la inclusión financiera.",
  },
  {
    title: "INNOVACIÓN Y TECNOLOGÍA PARA LAS FINANZAS SOSTENIBLES",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "Monedas digitales y billeteras en América Latina. Experiencias de los bancos centrales de la región.",
  },
  {
    title: "INCLUSIÓN FINANCIERA Y MICROFINANZAS",
    time: "00:00 - 00:00 PM/AM",
    textContent:
      "Éxito de programas de microfinanzas que facilitaron el acceso a servicios financieros en comunidades vulnerables. Políticas que promuevan la inclusión de género en el acceso a servicios financieros. Educación financiera: Herramientas para mejorar la alfabetización financiera en diferentes sectores. Herramientas para monitorear las iniciativas de inclusión financiera y medir el impacto socialde las mismas – casos de éxito.",
  },
];

const ThemesSection = () => {
  return (
    <Grid
      container
      sx={{ py: 4, px: 2, backgroundColor: "#64b76f" }}
      alignItems="center"
      direction="column"
      id="agenda"
    >
      <img
        src={"assets/Logos/congreso-small-white.png"}
        alt="Logo del congreso"
        height="60px"
      />
      <TitleContainer>
        <Title>EJES TEMÁTICOS</Title>
        <Button2 href="/assets/Themes/Agenda.pdf" target="_blank">
          <span>Agenda</span>
        </Button2>
      </TitleContainer>
      <GridContainer>
        {items.map((item) => (
          <ThemeCard {...item} />
        ))}
      </GridContainer>
    </Grid>
  );
};

export default ThemesSection;
