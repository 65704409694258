import { Box, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import DestinationCard from "./components/DestinationCard/DestinationCard";
import { Backdrop, EmptySlot, GridContainer, InfoContainer } from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const items = [
  {
    title: "Ushuaia",
    subtitle: "Escapada al fin del mundo",
    image: "/assets/Argentina/ushuaia.png",
    content: [
      {
        title: "SERVICIOS INCLUIDOS E ITINERARIO INCLUYE:",
        body: "Vuelos directos por Aerolíneas Argentinas con equipaje. 3 Noches de hotel 3*, 4* ó 5* con desayuno. Excursiones y traslados aeropuerto/hotel/aeropuerto. Impuestos.",
      },
      {
        title: "DIA 01 28/8/24",
        body: "Traslado desde su Hotel hasta Aer/Eze. Vuelo ida directo BUE/USH. Check in en hotel seleccionado.",
      },
      {
        title: "DIA 02 - 29/8/24",
        body: "Visita al Parque Nacional Tierra del Fuego con Tren del Fin del Mundo.",
      },
      {
        title: "DIA 03 - 30/8/24",
        body: 'Navegación de 2hs y media por el Canal de Beagle pasando por la Isla de los lobos y la Isla del Faro "Les Eclaireus". Con traslados',
      },
      {
        title: "DIA 04 - 31/8/24",
        body: "Check out. Mañana libre previo a su vuelo de regreso.",
      },
      {
        type: "note",
        body: "Nota: Consultar por más opciones y categorías en hotelería.",
      },
    ],
  },
  {
    title: "Bariloche",
    subtitle: "Lagos y Bosques patagónicos",
    image: "/assets/Argentina/bariloche.png",
    content: [
      {
        title: "SERVICIOS INCLUIDOS E ITINERARIO INCLUYE:",
        body: "Vuelos directos por Aerolíneas Argentinas con equipaje. 3 Noches de hotel 3*, 4* ó 4* superior, con desayuno. Excursiones y traslados aeropuerto/ hotel/ aeropuerto. Impuestos.",
      },
      {
        title: "DIA 01 28/8/24",
        body: "Traslado desde su Hotel hasta Aer/Eze. Vuelo ida directo BUE/BRC. Check in en hotel seleccionado.",
      },
      {
        title: "DIA 02 - 29/8/24",
        body: 'Excursión de medio día "Circuito Chico y punto panorámico".',
      },
      {
        title: "DIA 03 - 30/8/24",
        body: 'Navegación a "Isla Victoria y Bosque de Arrayanes". Medio día.',
      },
      {
        title: "DIA 04 - 31/8/24",
        body: "Check out. Mañana libre previo a su vuelo de regreso.",
      },
      {
        type: "note",
        body: "Nota: Consultar por más opciones y categorías en hotelería.",
      },
    ],
  },
  {
    title: "Mendoza",
    subtitle: "Techo de América, Viñedos & Bodegas",
    image: "/assets/Argentina/mendoza.png",
    content: [
      {
        title: "SERVICIOS INCLUIDOS E ITINERARIO INCLUYE:",
        body: "Vuelos directos por Aerolíneas Argentinas con equipaje. 3 Noches de hotel 3*, 4* ó 5* con desayuno. Excursiones y traslados aeropuerto/hotel/aeropuerto. Impuestos.",
      },
      {
        title: "DIA 01 28/8/24",
        body: "Traslado desde su Hotel hasta Aer/Eze. Vuelo ida directo BUE/MDZ. Check in en hotel seleccionado.",
      },
      {
        title: "DIA 02 - 29/8/24",
        body: 'Famosa excursión "Alta Montaña" a Paso del Inca, Aconcagua y Cuevas limítrofes con Chile.',
      },
      {
        title: "DIA 03 - 30/8/24",
        body: 'Viñedos & Bodegas con almuerzo, vino y sabores. "Maipú"',
      },
      {
        title: "DIA 04 - 31/8/24",
        body: "Check out. Mañana libre previo a su vuelo de regreso.",
      },
      {
        type: "note",
        body: "Nota: Consultar por más opciones y categorías en hotelería.",
      },
    ],
  },
  {
    title: "Cataratas del Iguazú",
    subtitle: "Maravilla Natural del Mundo",
    image: "/assets/Argentina/cataratas.png",
    content: [
      {
        title: "SERVICIOS INCLUIDOS E ITINERARIO INCLUYE:",
        body: "Vuelos directos por Aerolíneas Argentinas con equipaje. 2 Noches de hotel 4* ó 5*, con desayuno. Excursiones y traslados aeropuerto/ hotel/ aeropuerto. Impuestos.",
      },
      {
        title: "DIA 01 28/8/24",
        body: "Traslado desde su Hotel hasta Aer/Eze. Vuelo ida directo BUE/IGR. Check in en hotel seleccionado.",
      },
      {
        title: "DIA 02 - 29/8/24",
        body: "Cataratas del Iguazú, lado Argentino y Brasileño.",
      },
      {
        title: "DIA 03 - 30/8/24",
        body: "Check out. Vuelo de regreso.",
      },
      {
        type: "note",
        body: "Nota: Consultar por más opciones y categorías en hotelería.",
      },
    ],
  },
  {
    title: "Noroeste Argentino",
    subtitle: "Tren a las Nubes & colores norteños",
    image: "/assets/Argentina/noroeste.png",
    content: [
      {
        title: "SERVICIOS INCLUIDOS E ITINERARIO INCLUYE:",
        body: "Vuelos directos por Aerolíneas Argentinas con equipaje. 3 Noches de hotel 3*, 4* ó 5* con desayuno. Excursiones y traslados aeropuerto/hotel/aeropuerto. Impuestos.",
      },
      {
        title: "DIA 01 28/8/24",
        body: "Traslado desde su Hotel hasta Aer/Eze. Vuelo ida directo BUE/SLA. Check in en hotel seleccionado.",
      },
      {
        title: "DIA 02 - 29/8/24",
        body: "Excursión a Quebrada de Humahuaca",
      },
      {
        title: "DIA 03 - 30/8/24",
        body: "Tren a las Nubes con traslados",
      },
      {
        title: "DIA 04 - 31/8/24",
        body: "Check out. Mañana libre previo a su vuelo de regreso.",
      },
      {
        type: "note",
        body: "Nota: Consultar por más opciones y categorías en hotelería.",
      },
    ],
  },
  {
    title: "Buenos Aires",
    subtitle: "La vibrante y cosmopolita capital de Argentina a tu alcance",
    image: "/assets/Argentina/baires.png",
    content: [
      {
        title: "",
        body: "En el corazón de la ciudad se encuentra la Plaza de Mayo, rodeada por majestuosos edificios del siglo XIX, entre los que destaca la Casa Rosada. Entre los muchos lugares de interés se encuentra el Teatro Colón, un suntuoso teatro de ópera inaugurado en 1908 y el contemporáneo museo MALBA.",
      },
      {
        title: "CITY TOURS",
        body: "Opciones disponibles de 3 y 5 horas con distintos recorridos y conociendo atractivos mundialmente reconocidos.",
      },
      {
        type: "list",
        title: "OTRAS OPCIONES:",
        body: "- Cena Show Tango Internacional\n- Visitas Especiales: museos/estancas con tradiciones\n- Fútbol",
      },
      {
        title: "",
        body: "Buenos Aires es tango, es arte, es teatro, es gastronomía, es historia, es fútbol, es una ciudad que deslumbra. Cada rincón de una de las urbes más encantadoras de Latinoamérica te está esperando.",
      },
    ],
  },
];

const ArgentinaSection = () => {
  const iconRef = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setOpen] = useState(false);

  const showInfo = () => {
    setOpen(true);
    const rect = iconRef.current?.getBoundingClientRect();
    if (rect) {
      setPosition({
        x: window.innerWidth - rect.x - rect.width,
        y: rect.bottom + 4,
      });
    }
    document.body.style.overflow = "hidden";
  };

  const closeInfo = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <Grid
      container
      sx={{ py: 4, px: 2 }}
      alignItems="center"
      direction="column"
      id="argentina"
    >
      <Box
        display="flex"
        width="100%"
        gap={2}
        alignItems="flex-start"
        maxWidth={900}
      >
        <EmptySlot />
        <Typography
          variant="h5"
          fontWeight={800}
          sx={{ flex: 1 }}
          textAlign="center"
        >
          LA ARGENTINA QUE TENÉS QUE CONOCER
        </Typography>
        <InfoOutlinedIcon
          ref={iconRef}
          sx={{ fill: "#64b76f", mt: -0.5, cursor: "pointer" }}
          fontSize="large"
          onClick={showInfo}
        />
      </Box>

      {isOpen && (
        <Backdrop onClick={closeInfo}>
          <InfoContainer
            position={position}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography fontWeight={800} lineHeight={1.1}>
              Nota:
            </Typography>
            <Typography fontWeight={500} lineHeight={1.1}>
              Para cotizaciones y consultas:{" "}
              <a href="mailto:congreso@abappra.org.ar">
                congreso@abappra.org.ar
              </a>
            </Typography>
            <Typography fontWeight={500} lineHeight={1.1}>
              {" "}
              REF: TURISMO
            </Typography>
            <Typography fontWeight={500} lineHeight={1.1}>
              En todos los casos las cotizaciones se responden de manera
              individual y se confeccionan a medida de cada pasajero ya que
              dependerá de la cantidad de días disponibles en el país y de su
              ingreso y egreso.
            </Typography>
          </InfoContainer>
        </Backdrop>
      )}
      <GridContainer>
        {items.map((item) => (
          <DestinationCard {...item} />
        ))}
      </GridContainer>
    </Grid>
  );
};

export default ArgentinaSection;
