import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  BgImg,
  Button2,
  GridContainer,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  SponsorTab,
  Title,
} from "./styles";

const sponsors = [
  {
    title: "MAIN SPONSOR",
    soldOut: true,
    body: [
      {
        title:
          "Presencia exclusiva de la empresa, en primera línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title:
          "Naming del certamen para empresas comprometidas con el desarrollo sostenible",
        body: "Participación en la apertura del certamen y en la entrega de premios. Logo en countdown clock para la premiación.",
      },
      {
        title: "Cocktail vip de clausura, invita main sponsor",
        body: "La invitación a este encuentro incluye el logo del sponsor. Se contempla presencia exclusiva de la marca durante el evento y se autoriza la entrega de merchandising. El sponsor contará con 20 (veinte) invitaciones exclusivas.",
      },
      {
        title: "Participación en panel académico",
        body: "Su participación en paneles académicos será curada y dirigida por el comité académico del congreso, asegurando un enfoque alineado con los temas de agenda.",
      },
      {
        title: "Lote de 8 x 4 de exposición exclusiva",
        body: "Posición diferencial en salón de los escudos ubicado en el 2do. piso, misma ubicación que la sala principal*.",
      },
      {
        title: "Mención especial por parte de los conductores del evento",
        body: "En intervenciones de auditorio principal y salón de honor. ",
      },
      {
        title:
          "Autorización para placement de productos durante el evento y en los sectores definidos ",
        body: "(podrá incluir merchandising autorizado en el kit de participante).",
      },
      {
        title: "Proyección en pantallas de spot/corto publicitario ",
        body: "(hasta 40 segundos) en auditorio principal y salón de honor**",
      },
      {
        title: "Almuerzo ejecutivo de networking",
        body: "El sponsor dispondrá de 2 (dos) invitaciones a estos encuentros que se desarrollarán durante el evento.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 50 (cincuenta) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 30% de descuento para los pases adicionales que requiera.",
      },
    ],
    clarifications: [
      "*El sponsor podrá proyectar y desarrollar su stand a gusto respetando las normas establecidas en el reglamento de exposición",
      "**El spot a proyectar debe ser entregado por el sponsor para su revisión y supervisión respetando las fechas establecidas.",
    ],
  },
  {
    title: "PREMIUM",
    body: [
      {
        title:
          "Presencia exclusiva de la empresa, en segunda línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title: "Participación en panel académico",
        body: "Su participación en paneles académicos será curada y dirigida por el comité académico del congreso, asegurando un enfoque alineado con los temas de agenda.",
      },
      {
        title: "Lote de 6 x 3 de exposición exclusiva",
        body: "Posición diferencial en salón de los escudos ubicado en el 2do. piso, misma ubicación que la sala principal*.",
      },
      {
        title: "Mención especial por parte de los conductores del evento",
        body: "En intervenciones de auditorio principal y salón de honor. ",
      },
      {
        title:
          "Autorización para placement de productos durante el evento y en los sectores definidos ",
        body: "(podrá incluir merchandising autorizado en el kit de participante).",
      },
      {
        title: "Proyección en pantallas de spot/corto publicitario ",
        body: "(hasta 30 segundos) en auditorio principal y salón de honor**",
      },
      {
        title: "Cocktail vip de clausura",
        body: "10 (diez) invitaciones exclusivas.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 30 (treinta) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 25% de descuento para los pases adicionales que requiera.",
      },
    ],
    clarifications: [
      "*El sponsor podrá proyectar y desarrollar su stand a gusto respetando las normas establecidas en el reglamento de exposición",
      "**El spot a proyectar debe ser entregado por el sponsor para su revisión y supervisión respetando las fechas establecidas.",
    ],
  },
  {
    title: "DIAMANTE",
    body: [
      {
        title:
          "Presencia de la empresa, en tercera línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title: "Participación en panel corporativo",
        body: "La empresa podrá asignar un speaker* para los paneles corporativos que se desarrollen en el salón de honor.",
      },
      {
        title: "Stand 3x2 en espacio de exposición",
        body: "Podrá disponer de stand llave en mano en plaza seca ubicada en planta baja, donde el sponsor podrá presentar y promocionar productos para el mercado local e internacional**",
      },
      {
        title: "Mención especial por parte de los conductores del evento",
        body: "En intervenciones de auditorio principal y salón de honor. ",
      },
      {
        title:
          "Autorización para placement de productos durante el evento y en los sectores definidos ",
        body: "(podrá incluir merchandising autorizado en el kit de participante).",
      },
      {
        title: "Cocktail vip de clausura",
        body: "2 invitaciones exclusivas.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 10 (diez) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 20% de descuento para los pases adicionales que requiera.",
      },
    ],
    clarifications: [
      "*El comité académico aprobará dicho speaker previa revisión de su CV y experiencia en eventos similares.",
      "**El sponsor recibirá stand listo para su utilización (con electricidad y mobiliario según detalle en anexo), solo deberá hacer entrega de gráficas y diseños solicitados para la producción del mismo",
    ],
  },
  {
    title: "PLATINO",
    body: [
      {
        title:
          "Presencia de la empresa, en la cuarta línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title: "Stand 3x2 en espacio de exposición",
        body: "Podrá disponer de stand “llave en mano* en plaza seca ubicada en planta baja, donde el sponsor podrá presentar y promocionar productos para el mercado local e internacional.",
      },
      {
        title: "Cocktail vip de clausura",
        body: "2 invitaciones exclusivas.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 5 (cinco) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 15% de descuento para los pases adicionales que requiera.",
      },
    ],
    clarifications: [
      "*El sponsor recibirá stand listo para su utilización (con electricidad y mobiliario según detalle en anexo), solo deberá hacer entrega de gráficas y diseños solicitados para la producción del mismo.",
    ],
  },
  {
    title: "ORO",
    body: [
      {
        title:
          "Presencia de la empresa, en la quinta línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 2 (dos) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 10% de descuento para los pases adicionales que requiera.",
      },
    ],
  },
  {
    title: "PLATA",
    body: [
      {
        title:
          "Presencia de la empresa, en la quinta línea, en imagen del evento",
        body: "Campaña publicitaria y sitio web, mailing semanal a base de datos de más de 50.000 contactos nacionales e internacionales, flyers, comunicación digital, banners en pantallas del evento.",
      },
      {
        title: "Pases incluidos",
        body: "El sponsor dispondrá de 2 (dos) entradas para cada jornada",
      },
      {
        title: "Descuento para pases adicionales",
        body: "El sponsor contará con un 5% de descuento para los pases adicionales que requiera.",
      },
    ],
  },
  {
    title: "OTRAS OPCIONES",
    body: [
      {
        title: "Coffee break: Consultar costo",
        body: "La invitación al coffee incluirá el logo con el nombre de la empresa anfitriona. Presencia exclusiva en las pantallas del espacio durante el desarrollo del coffee break. Se podrá hacer entrega de merchandising.",
      },
      {
        title: "Ágape de bienvenida: Consultar costo",
        body: "El mismo se desarrollará el lunes 26/08 luego de concluida la jornada académica, contará con la presencia de todos los asistentes y tendrá lugar en la plaza seca en planta baja. Duración 45 minutos. Presencia exclusiva en las pantallas del espacio durante el desarrollo del mismo. Se podrá hacer entrega de merchandising.",
      },
      {
        title: "Almuerzo ejecutivo de networking: Consultar costo",
        body: "Reunión de 40 referentes, autoridades y directivos para una oportunidad de intercambio y relacionamiento personalizado. Servicio de 3 (tres) pasos completos con bebida. El sponsor dispondrá de 20 (veinte) invitaciones y podrá sumar presencia de marca al espacio donde se desarrollen.",
      },
    ],
  },
];

const SponsorshipsSection = () => {
  const modal = useRef();
  useEffect(() => {
    modal.current = document.getElementById("modal");
    const clickHandler = (event) => {
      if (event.target === modal) {
        modal.current.style.display = "none";
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
      }
    };
    window.addEventListener("click", clickHandler);
    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, []);

  const [sponsorId, setSponsorId] = useState(0);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 4, px: "15%" }}
        id="sponsors"
        position="relative"
      >
        <BgImg src="/assets/Sponsors/sponsorship-misc.png" alt="misc" />
        <img
          src={"assets/Logos/congreso-small-green.png"}
          alt="Logo del congreso"
        />
        <Title>PLAN DE BENEFICIOS PARA SPONSORS</Title>
        <GridContainer>
          {sponsors.map((sponsor, idx) => (
            <SponsorTab
              onClick={() => {
                setSponsorId(idx);
                modal.current.style.display = "flex";
                document.body.style.overflow = "hidden";
                document.body.style.paddingRight = "20px";
              }}
              soldOut={sponsor.soldOut ?? false}
            >
              <Typography
                variant="h5"
                color="white"
                fontWeight={800}
                textAlign="center"
                py={2}
              >
                {sponsor.title}
              </Typography>
            </SponsorTab>
          ))}
        </GridContainer>
        <Button2 href="mailto:congreso@abappra.org.ar">
          <span>CONOCE MÁS</span>
        </Button2>
      </Grid>
      <Modal id="modal">
        <ModalContent>
          <ModalHeader>
            <h3 style={{ margin: "4px 0" }}>{sponsors[sponsorId].title}</h3>
            <Button
              onClick={() => {
                modal.current.style.display = "none";
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0";
              }}
              style={{
                fontSize: "2em",
                height: "32px",
                width: "40px",
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: 0,
                color: "#fff",
                backgroundColor: "rgb(100, 183, 111)",
                borderRadius: 0,
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              &times;
            </Button>
          </ModalHeader>
          <ModalBody>
            <ol>
              {sponsors[sponsorId].body.map(({ title, body }) => (
                <>
                  <li>
                    <strong>{title}</strong>
                  </li>
                  <p style={{ margin: "0 0 4px 16px" }}>{body}</p>
                </>
              ))}
            </ol>
            {sponsors[sponsorId].clarifications && (
              <ul style={{ listStyle: "none", paddingLeft: "32px" }}>
                {sponsors[sponsorId].clarifications.map((clarification) => (
                  <li>{clarification}</li>
                ))}
              </ul>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SponsorshipsSection;
