import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  min-height: 760px;
  overflow: hidden;

  @media (max-width: 990px) {
    display: flex;
    justify-content: center;
  }
`;

export const Imgbackground = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 50%;
  height: auto;
  max-height: 800px;
  z-index: 0;
  object-fit: cover;
  object-position: bottom;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const ContainerText = styled.div`
  float: right;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 480px;
  padding: 24px 0;

  & img {
    width: auto;
    height: 60px;
  }

  @media (max-width: 990px) {
    width: 100%;
    align-items: center;
    padding-right: 0px;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  margin: 12px 0px 0px 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const SubTitle = styled.h3`
  color: #365b9b;
  margin: 24px 0px 0px 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Text = styled.h4`
  margin: 0px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Content = styled.div`
  width: 50%;
  float: right;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 990px) {
    float: none;
    width: 100%;
    padding: 24px;
    justify-content: center;
  }
`;
