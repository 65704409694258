import { Grid, Typography } from "@mui/material";
import React from "react";
import PressCard from "./components/DiscoverCard/PressCard";
import { Container } from "./styles";

const items = [
  {
    title: "Gacetillas",
    items: [
      {
        name: "Información",
        url: "/assets/Press/II Congreso Latinoamericano de Banca Sostenible e Inclusiva.pdf",
      },
      {
        name: "Te esperamos en el II Congreso Latinoamericano de Banca Sostenible e Inclusiva",
        url: "https://mailchi.mp/261f5768b4ea/te-esperamos-en-el-ii-congreso-latinoamericano-de-banca-sostenible-e-inclusiva?e=[UNIQID]",
      },
      {
        name: "Apertura",
        url: "/assets/Press/Apertura.pdf",
      },
      {
        name: "Mesas de Finanzas",
        url: "/assets/Press/Panel02-Mesas-de-Finanzas.pdf",
      },
      {
        name: "Evaluación de Factores ASG",
        url: "/assets/Press/Panel03-Evaluación-Factores-ASG.pdf",
      },
      {
        name: "Medición de impacto",
        url: "/assets/Press/Panel05-Medición-de-Impacto.pdf",
      },
    ],
  },
  {
    title: "Notas y videos",
    items: [
      {
        name: "Ámbito - 02 de Julio",
        url: "https://www.ambito.com/economia/bancos-y-empresas-la-region-se-reunen-debatir-sostenibilidad-e-inclusion-buenos-aires-n6024833",
      },
      {
        name: "Ámbito - 08 de Julio",
        url: "https://www.ambito.com/economia/los-bancos-buscan-agenda-federal-foco-reducir-asimetrias-las-fintechs-y-limpiar-los-puts-n6028261",
      },
      {
        name: "Ámbito - 16 de Agosto",
        url: "https://www.ambito.com/economia/la-inclusion-financiera-y-la-sustentabilidad-seran-parte-la-agenda-bancos-y-empresas-toda-la-region-n6049486",
      },
      {
        name: "Noticias Argentinas - 05 de Julio",
        url: "https://noticiasargentinas.com/economia/argentina-sera-sede-de-un-encuentro-de-banca-verde--sostenible-e-inclusiva_a66882d98eeb685090c4564d5",
      },
      {
        name: "Noticias Argentinas - 18 de Agosto (I)",
        url: "https://noticiasargentinas.com/economia/inclusion-y-sustentabilidad--bancos-y-empresas-de-la-region-se-reunen-en-buenos-aires_a66c21a3b5567e17785b79fbd",
      },
      {
        name: "Noticias Argentinas - 18 de Agosto (II)",
        url: "https://noticiasargentinas.com/economia/mazzon---queremos-que-el-financiamiento-bancario-crezca-para-impulsar-el-desarrollo--la-sostenibilidad-y-la-inclusion-financiera-_a66c226e95567e17785b99bc5",
      },
      {
        name: "Bank Magazine",
        url: "/assets/Press/Bank Magazine 84 Jul 24.pdf",
      },
      {
        name: "Perfil",
        url: "https://www.perfil.com/noticias/amp/canal-e/banco-sostenible-e-inclusion-financiera-la-banca-esta-desafiando-al-mercado.phtml",
      },
      {
        name: "Infobae",
        url: "https://www.infobae.com/opinion/2024/08/02/el-credito-bancario-y-su-impacto-en-el-desarrollo-la-sostenibilidad-y-la-inclusion-financiera/",
      },
      {
        name: "Reporte ASG",
        url: "https://reporteasg.com/contenido/1767/felaban-y-abappra-ii-congreso-latinoamericano-banca-sostenible-e-inclusiva",
      },
      {
        name: "Economía Sustentable",
        url: "https://economiasustentable.com/noticias/se-viene-el-congreso-latinoamericano-de-banca-sostenible-e-inclusiva-donde-y-cuando/",
      },
      {
        name: "Somos Pymes",
        url: "https://www.somospymes.com.ar/financiamiento-y-mercados/argentina-sera-sede-un-encuentro-banca-sostenible-e-inclusiva-n5395489",
      },
      {
        name: "Ecobiz",
        url: "https://www.ecobiz.com.ar/nota/203-La-banca-verde-tiene-cita-en-Argentina",
      },
      {
        name: "Diario San Rafael",
        url: "https://diariosanrafael.com.ar/mazzon-queremos-que-el-financiamiento-bancario-crezca-para-impulsar-el-desarrollo/",
      },
      {
        name: "La Prensa",
        url: "https://www.laprensa.com.ar/Mazzon-Queremos-que-el-financiamiento-bancario-crezca-para-impulsar-el-desarrollo-549033.note.aspx",
      },
      {
        name: "Infodiez",
        url: "https://www.infodiez.com/ii-congreso-latinoamericano-de-banca-sostenible-e-inclusiva-en-buenos-aires/amp/",
      },
      {
        name: "Clarín",
        url: "https://www.clarin.com/economia/bancos-sostenibilidad-relacion-debatira-expertos-region_0_DjfFOZd1T1.html",
      },
      {
        name: "BusinessTrend",
        url: "https://businesstrend.com.ar/ii-congreso-latinoamericano-de-banca-sostenible-e-inclusiva/",
      },
    ],
  },
  // {
  //   title: "Media Kit",
  //   items: [{ name: "Descargar Media Kit", url: "#" }],
  // },
  {
    title: "Webinars",
    items: [
      {
        name: "Banca y Sostenibilidad",
        url: "https://www.youtube.com/live/Fco-6iaQO2M",
      },
      {
        name: "Bonos Sostenibles",
        url: "https://www.youtube.com/watch?v=xkdjiywGoxA",
      },
    ],
  },
];

const PressSection = () => {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      sx={{
        my: 2,
        py: 4,
        background:
          "url('/assets/Press/background.jpg') center 100% / cover no-repeat",
      }}
      id="press"
    >
      <img
        src={"assets/Logos/congreso-small-green.png"}
        alt="Logo del congreso"
        width="60"
      />
      <Typography variant="h5" fontWeight={800} sx={{ my: 2 }}>
        ÚLTIMAS NOVEDADES DEL CONGRESO
      </Typography>

      <Container container sx={{ my: 6 }}>
        {items.map((item) => (
          <PressCard {...item} />
        ))}
      </Container>
    </Grid>
  );
};

export default PressSection;
