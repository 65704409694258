import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    max-height: 780px;
    position: absolute;

    top: 0px;
    left: 0px;
    z-index: -1;
  }
`;

export const ContainerCountDown = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  min-height: 780px;
  text-align: center;
  padding: 0px 16px;
`;

export const ContainerCount = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  gap: 32px;
  @media (max-width: 780px) {
    flex-direction: column;
    gap: 0px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const ContainerDateInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 24px 0px;
`;

export const Numbers = styled.div`
  display: flex;
  flex-direction: row;

  gap: 12px;
`;

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Bebas Neue";
  font-size: 120px;
  text-align: center;

  width: 50%;
  background-color: #7edbaa;
  width: 102px;
  height: 164px;
`;

export const Type = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: large;
  border-radius: 0px;
  border: 1px solid #63b66e;
  padding: 4px;
`;

//------------------------------------------

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  margin: 32px 0px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Input = styled.input`
  padding: 10px;

  border: 1px solid #63b66e;
`;

export const Button2 = styled(Button)`
  display: inline-block;
  width: fit-content;
  color: white;
  font-weight: 600;
  border-radius: 0px;
  background-color: #63b66e;
  padding: 4px 24px;
  &:hover {
    background-color: rgba(100, 183, 111, 0.5) !important;
  }
`;
